.main-container {
    position: fixed;
    top: 0;
    left: 0;
    /* background: url("../assets/images/ori-logo.png") fixed no-repeat center; */
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}

.LoginScreenImage {
    width: 65%;
    display: grid;
    place-items: center;
}

.login-form {
    background-color: #fdfdfd98;
    width: 35%;
    backdrop-filter: blur(40px);
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2%;
    box-shadow: 0.3rem 0.3rem 5rem lightgrey;
    border-radius: 0.3rem;
    border: 0.1rem solid grey;
}

.gradient-header {
    background: linear-gradient(to bottom right, #25834f, #34aa34, rgb(17, 3, 20));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5rem;
}

.login-form>div {
    padding: 2%;
}

.login-form>div>h2 {
    margin: 0.5rem 0;
}

.form-cont {
    display: flex;
    border: 0.1rem solid grey;
    padding: 0.6rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    background-color: white;
}

.form-cont>h2 {
    text-align: center;
    width: 10%;
}

.form-cont>input {
    outline: none;
    border: none;
    background: none;
    border-left: 0.1rem solid grey;
    padding-left: 1rem;
    font-size: 1.5rem;
}

.submit-button {
    border: 0.1rem solid rgb(0, 0, 0);
    background: linear-gradient(to bottom right, #50C878, #98FB98, lightgreen);
    outline: none;
    padding: 1rem;
    border-radius: 5rem;
    font-size: 1.8rem;
    width: 50%;
    font-weight: bold;
}


@media(max-width: 768px) {
    .login-form {
        width: 100%;
    }

    .main-container {
        padding: 2rem;
        flex-direction: column;
        align-items: center;
    }

    .gradient-header {
        font-size: 3.5rem;
    }

}