.TestContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.test-container-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.test-container-header-child1 {
    width: 50%;
    display: flex;
}

.test-container-header-child2 {
    display: flex;
    width: 50%;
}

.test-container-header-child2 div:nth-child(1) {
    width: 50%;
}


.test-container-header-child2 div:nth-child(2) {
    display: none;
    width: 50%;
}

.auto-select-case-from-list {
    width: 100%;
    min-height: 20rem;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    background-color: #f6f6f6;
}

.auto-select-case-from-list-child-1 {
    background-color: white;
    width: 25%;
    overflow-y: scroll;
    border: 0.1rem solid grey;
}

.auto-select-case-from-list-child-2 {
    width: 74%;
}

.text-search-area {
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
    position: sticky;
    top: 0;
    padding: 1rem;
    background-color: white;
}

@keyframes imagerotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.spinner-image {
    animation: imagerotation 1s infinite linear;
}

.remove-scrollbar::-webkit-scrollbar {
    display: none;
}

@media(max-width: 768px) {
    .auto-select-case-from-list-child-1 {
        display: none;
    }

    .auto-select-case-from-list-child-2 {
        width: 100%;
    }

    .test-container-header {
        flex-direction: column;
    }

    .test-container-header-child1 {
        width: 100%;
    }

    .test-container-header-child2 {
        width: 100%;
        padding: 1rem 0;
    }

    .test-container-header-child2 div:nth-child(2) {
        display: block;
        width: 50%;
    }
/* 
    .auto-select-case-from-list-child-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        left: 0;
    } */
}

.tag {
    border: 0.1rem solid lightgrey;
    padding: 1rem 2rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
}