.api-log-viewer-header {
    display: flex;
    padding: 1%;
    font-size: 1.6rem;
    margin: 1rem 0;
}

.api-log-viewer {
    display: flex;
    padding: 1%;
    font-size: 1.6rem;
    margin: 1rem 0;
    background-color: white;
    box-shadow: 0.1rem 0.1rem 0.5rem grey;
    border: 0.1rem solid lightgrey
}

@media (max-width: 768px) {
    .api-log-viewer, .api-log-viewer-header {
        width: 70rem;
    }

    .container {
        overflow: auto;

    }
}