.MainContainer {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    box-shadow: 0.1rem 0.1rem 1rem lightgrey;
    padding: 1%;
    min-height: 100%;
}

.form-div {
    background-color: white;
    padding: 2%;
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
}

.form {
    display: flex;
    justify-content: space-between;
}

#processing_file {
    width: 35%;
    border: 0.1rem solid lightgrey;
    border-radius: 0.3rem;
    padding: 0.5rem;
}

#channel {
    font-size: 1.4rem;
    width: 35%;
    border: 0.1rem solid lightgrey;
    border-radius: 0.3rem;
    padding: 0.5rem
}

#submit {
    width: 15%;
}

.live-preview-playground {
    /* height: 48rem; */
    flex: 1;
    overflow: auto;
    background-color: #181B1E;
    color: #32CD32;
    padding: 2%;
    margin-top: 2%; 
}