* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}


/* Styling Scrollbar -> */
*::-webkit-scrollbar {
  background-color: lightgray;
  width: 0.9rem;
  height: 0.9rem;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  background-color: grey;
  border-radius: 5rem;
}
