.analytics-band {
    display: flex;
    overflow: auto;
    padding: 1rem;
    background-color: aliceblue;
    
}

.analytics-band > div{
    margin-right: 1rem;
    background-color: white;
    text-align: center;
    padding: 1rem;
    border: 0.1rem solid lightgrey;
    border-radius: 0.3rem;
    cursor: pointer;
}

.analytics-band > div:nth-child(1) {
    width: 5%;
}

.analytics-band > div:nth-child(2) {
    width: 15%;
}

.insights {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
}

.insights::-webkit-scrollbar {
    height: 0.8rem;
    background: #b8b2b2;
}

.insights::-webkit-scrollbar-thumb {
    height: 0.8rem;
    background: rgb(18, 192, 18);
    border-radius: 5rem;
}

.insights-div {
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
    border-radius: 0.5rem;
    text-align: center;
    width: 20rem;
    background-color: white;
    padding: 1%;
    flex-shrink: 0;
    margin-right: 1.5rem;
    border: 0.1rem solid lightgrey;
}

.insights-div:hover{
    color: green;
    border: 0.1rem solid green;
}

.web-mobile {
    display: none;
}

.hour-content {
    display: flex;
    align-items: flex-end;
    padding: 2%;
    overflow-x: scroll;
    background-color: white;
    height: 30rem;
    box-shadow: 0.1rem 0.1rem 0.3rem 0.3rem lightgrey;
}

.hour-content::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px) {
    /* .insights {
        padding: 8%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .insights-div {
        box-shadow: 0.1rem 0.1rem 1rem lightgrey;
        border: 0.1rem solid grey;
        border-radius: 0.5rem;
        text-align: center;
        margin-bottom: 2rem;
        width: 100%;
        background-color: white;
        padding: 12% 10%;
        flex-shrink: 0;
    } */

    .web-web {
        display: none;
    }

    .web-mobile {
        display: block;
        padding: "1rem";
    }
}