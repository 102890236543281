.email-history-container {
    display: flex;
    justify-content: space-between;
}

.email-history-container-child-1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 2%;
    font-size: 1.6rem;
}

.email-history-container-child-2 {
    width: 50%;
    font-size: 1.6rem;
    padding: 0% 2%;
}

@media (max-width: 768px) {
    .email-history-container {
        flex-direction: column;
    }

    .email-history-container > div {
        width: 100%;
    }

}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner-image {
    animation: spinner 3s linear infinite;
}

.fetch-button {
    border: 0.1rem solid grey;
    padding: 1.5 rem;
    width: 20rem;
    padding: 1rem;
    background-color: brown;
    color: white;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: bold;
}