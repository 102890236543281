.MainBox {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 100%;
}

.side-nav-bar {
    overflow-y: none;
    border-right-width: 0.1rem;
    border-right-color: lightgrey;
    border-right-style: solid;
    width: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
}

.side-nav-bar:hover {
    overflow-y: auto;
}

.side-nav-bar::-webkit-scrollbar {
    background: none;
    width: 0.5rem;
}

.side-nav-bar::-webkit-scrollbar-thumb {
    border-radius: 5rem;
    background-color: lightgrey;
}

.main-window-viewer {
    width: 95%;
}

/* Link Main with icon and text */
.link_div {
    display: flex;
    justify-content: center;
    height: 6rem;
    align-items: center;
    padding: 2% 5%;
    width: 100%;
    font-size: 1rem;
    color: grey;
    text-decoration: none;
}

.img-text {
    text-decoration: none;
}

input {
    width: 100%;
    font-size: 1.4rem;
    padding: 0.5rem;
}


hr {
    border-top: 0.1rem dotted grey;
}

.link1 {
    display: grid;
    place-items: center;
    text-decoration: none;
    color: black;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 6rem;
    transition: all 0.4s linear;
}

.link1:hover {
    background-color: lightgreen;
}

.link2 {
    width: 80%;
    text-decoration: none;
    color: black;
    font-weight: bold;
    display: none;
}

.img {
    width: 2.5rem;
    padding: 0.2rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;
}

/* //////////////////////////////////////// MOBILE /////////////////////////////////////////////// */
.mobile-nav-bar-header {
    display: none;
}



@media(max-width: 768px) {

    .side-nav-bar {
        width: 80%;
        height: 100%;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        background-color: rgba(255, 255, 255, 0.6);

        backdrop-filter: blur(10px);
    }

    .main-window-viewer {
        width: 100%;
    }

    .mobile-nav-bar-header {
        display: flex;
        justify-content: space-between;
        padding: 2%;
        align-items: center;
    }

    .icon {
        width: 30%;
        z-index: 0;
        box-shadow: none;
    }

    .menu {
        font-size: 3rem;
        width: 10%;
    }

    .wow {
        display: flex;
    }

    .link_div {
        height: 4rem;
    }

    .link1 {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: space-between;
    }

    .img-text {
        width: 80%;
        font-size: 1.6rem;
    }
}