.history-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.history-viewer-header {
    position: sticky;
    top: 0;
    z-index: 9999;
}


.history-viewer-container {
    width: 100%;
    flex-grow: 1;
    min-height: 20rem;
    justify-content: space-between;
    display: flex;
}

.history-viewer-container>div:nth-child(1) {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 25%;
}

/* User query and out put section */
.history-viewer-container>div:nth-child(2) {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: aliceblue;
}

.history-viewer-container>div:nth-child(3) {
    overflow-y: scroll;
    width: 25%;
    background-color: white;
    padding: 1rem;
}

.history-comp {
    padding: 1rem;
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
    cursor: pointer;
    background-color: white;
    margin: 1rem 0;
}

.history-comp>p {
    margin: 0.5rem 0;
}

.history-comp:hover {
    background-color: lightgreen;
}

.query-viewer {
    outline: none;
    border: 0.1rem solid lightgrey;
    border-radius: 0.4rem;
    margin: 0.3rem 0;
    padding: 1rem;
    font-size: 1.1rem;
}

details {
    padding: 1rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
    border: 0.1rem solid lightgrey;
    border-radius: 0.3rem;
}

summary {
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    border-bottom: 0.1rem solid lightgreen;
    font-weight: bold;
}


.mail-and-user-info>details:nth-child(2) {
    border-left: 0.4rem solid green;
}

.mail-and-user-info>details:nth-child(3) {
    border-left: 0.4rem solid green;
}

.mail-and-user-info>details:nth-child(4) {
    border-left: 0.4rem solid green;
}

.mail-and-user-info>details:nth-child(5) {
    border-left: 0.4rem solid green;
}

.mail-and-user-info>details:nth-child(6) {
    border-left: 0.4rem solid green;
    overflow: auto;
}

.mail-and-user-info>details:nth-child(7) {
    border-left: 0.4rem solid green;
    overflow: auto;
}

.mail-and-user-info>div p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
}