.workflow-container {
    padding: 1%;
}

.flex-as-column {
    display: flex;
    flex-direction: column;
}

.workflow-row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    border: 0.1rem solid lightgrey;
}


.workflow-row-header>p:nth-child(1) {
    width: 10%;
    font-size: 1.4rem;
}

.workflow-row-header>p:nth-child(2) {
    width: 40%;
    font-size: 1.4rem;
}

.workflow-row-header>p:nth-child(2)>input {
    border: none;
    outline: none;
    background: none;
    font-size: 1.4rem;
}

.workflow-row-header>p:nth-child(3) {
    width: 30%;
    font-size: 1.4rem;
}

.workflow-row-header>p:nth-child(4) {
    width: 20%;
    font-size: 1.4rem;
    text-align: center;
}


.workflow-row {
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    margin-bottom: 1rem
}

.workflow-row>p:nth-child(1) {
    width: 10%;
    font-size: 1.4rem;
}

.workflow-row>p:nth-child(2) {
    width: 36%;
    font-size: 1.4rem;
}

.workflow-row>p:nth-child(3) {
    width: 34%;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.workflow-row>p:nth-child(4) {
    width: 10%;
    font-size: 1.4rem;
    text-align: center;
    cursor: pointer;
}

.workflow-row>p:nth-child(5) {
    width: 10%;
    font-size: 1.4rem;
    text-align: center;
    cursor: pointer;
}

.rr-link {
    color: black;
    text-decoration: none;
}