.template-container {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    overflow: auto;
    padding-right: 1%;
    width: 100%;
    flex-shrink: 0;
}

.template-name-search-input {
    background: none;
    border: none;
    outline: none;
}

.template{
    min-height: 20rem;
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1% 0 1% 1%;
    
}

@media (max-width: 768px) {
    .template-container {
        width: 70rem;
    }

    .template {
        overflow: auto;
    }

}