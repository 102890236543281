.header-container {
    z-index: 9999;
    background-color: white;
    padding: 1%;
    box-shadow: 0.1rem 0.1rem 0.5rem lightgrey;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.header-container>div:nth-child(1) {
    width: 95%;
}

.header-container>div:nth-child(2) {
    width: 5%;
}

@media(max-width: 768px) {
    .header-container>div:nth-child(1) {
        width: 100%;
    }

    .header-container>div:nth-child(2) {
        display: none;
    }
}