.modal-container {
    z-index: 999999999;
    left: 0;
    top: 0;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
}

.feed-textarea{
    border-top-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    outline: none;
    border: 0.1rem solid grey;
    resize: none;
    margin: 1rem 0;
    flex: 1;
    padding: 1%;
}

.modal-viewer {
    display: flex;
    padding: 1.5% 2%;
    flex-direction: column;
    width: 80%;
    height: 70%;
    background: rgba(255, 255, 255);
    border-top-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

@media (max-width: 78rem) {
    .modal-viewer {
        width: 90%;
        height: 70%;
        padding: 5%;
    }
    .feed-textarea{
        padding: 2rem;
    }
}