.AI-header {
    display: flex;
    align-items: center;
}

.AI-header > div:nth-child(1) {
    width: 30%;
}

.AI-header > div:nth-child(2) {
    width: 40%;
    display: flex;
}

.AI-header > div:nth-child(2) > button {
    margin-right: 2rem;
    padding: 0.5rem 2rem;
    border-radius: 0.3rem;
    border: 0.1rem solid grey;
}

/* ////////// MID LEVEL INTENT -> Start */
.mid-level-container{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.mid-level-container > div:nth-child(1) {
    width: 100%;
    background-color: rgb(212, 232, 87);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    /* margin: 1rem 0; */
}

.mid-level-container > div:nth-child(2) {
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    flex: 1;
}

/* ////////// MID LEVEL INTENT -> End */