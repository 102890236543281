.intent-type {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
}

.intent-header {
    z-index: 999;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.intent-header-select-intent-type {
    width: 40%;
}

button {
    border: none;
}

.intent,
.mid-intent,
.high-intent {
    font-weight: bold;
    border-radius: 0.2rem;
    padding: 1.5%;
    font-size: 1.4rem;
    border: 0.1rem solid grey;
}

.download-button {
    padding: 0.2rem 1.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
    background: linear-gradient(to bottom right, #50C878, #98FB98, lightgreen);
    font-size: 1.6rem;
}

.download-button:hover {
    background: linear-gradient(to bottom right, #98FB98, lightgreen, #98FB98);
}

@media (max-width: 768px) {
    .intent-type {
        width: 100%;
        font-size: 1rem;
        z-index: 9;
        padding: 2%;
    }

    .intent-header {
        flex-direction: column;
    }

    .intent-header-select-intent-type {
        display: none;
    }


    .high-level-intent-area {
        width: 75rem;
    }
}